<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="font-weight-semibold text-h5 ">Dashboard for Medical Director</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="6"
          md="4"
          class="d-flex align-center"
        >
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon
              color="white"
              size="35"
            >
            {{icons.mdiAccountOutline}}
              
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Total Collected Tissue
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{dashboarddata.total_collection}}
            </h3>
          </div>
        </v-col>

        <v-col
          cols="6"
          md="4"
          class="d-flex align-center"
        >
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon
             
              color="white"
              size="35"
        
            >
        {{icons.mdiCurrencyUsd}}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Total Transplanted Tissue
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{dashboarddata.total_transplant}}
            </h3>
          </div>
        </v-col>

        <v-col
          cols="6"
          md="4"
          class="d-flex align-center"
        >
          <v-avatar size="50" rounded class="elevation-1 primary">
            <v-icon
             
              color="white"
              size="35"
        
            >
           {{icons.mdiTrendingUp}}
            </v-icon>
          </v-avatar>
          <div class="ms-3">
            <p class="text-xs mb-0">
              Rejected Tissue
            </p>
            <h3 class="text-xl font-weight-semibold">
              {{dashboarddata.rejectedTissue}}
            </h3>
          </div>
        </v-col>
      

      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline, mdiCurrencyUsd, mdiTrendingUp, mdiDotsVertical, mdiLabelOutline, mdiCalendarRange } from '@mdi/js'
import daskboardserice from '@/service/Dashboard.service'

export default {
    data() {
    return {
      dashboarddata:[],
       icons: {
        mdiAccountOutline,
        mdiCurrencyUsd,
        mdiTrendingUp,
        mdiDotsVertical,
        mdiLabelOutline,
        mdiCalendarRange
      },
      
      
    }
  },
  watch: {
    options: {
      handler() {
        this.ViewDaskBoard()
      },
      deep: true,
    },
  },
  async mounted() {
    this.ViewDaskBoard()
  },

  methods:{
    async ViewDaskBoard(){
      const service = new daskboardserice()
      let response = await service.getDaskboard()
      this.dashboarddata = response
    
      // console.log("Hello dasboad", this.dashboarddata.total_donor)
    },
  }
}
</script>
