<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Weekly Collection & Transplant</span>

      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text>
     
     
      <div id="chart"></div>

      
    </v-card-text>
  </v-card>
</template>

<script>

import { mdiDotsVertical, mdiTrendingUp, mdiCurrencyUsd } from '@mdi/js'
import { getCurrentInstance } from '@vue/composition-api'
import daskboardserice from '@/service/Dashboard.service'
import moment from 'moment'
export default {

  setup() {
    const ins = getCurrentInstance()?.proxy
    const $vuetify = ins && ins.$vuetify ? ins.$vuetify : null
    const customChartColor = $vuetify.theme.isDark ? '#3b3559' : '#f5f5f5'


    return {

      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiCurrencyUsd,
      },
    }
  },
   
   
   watch: {
    options: {
      handler() {
        this.chartMap()
      },
      deep: true,
    },
  },
  mounted() {
    this.chartMap()

  },
  methods:{

    async chartMap(){
      const service = new daskboardserice()
      let response = await service.getDaskboard()
      
      var data11 = response
      var data1 = data11.weekly_collection
      var data2 = data11.weekly_transplant
      // var data3 = data11.weekly_optical
      // var data4 = data11.weekly_therapeutic
      const sectorCall = data1.map((d) => (d["count"]))
      const days = data1.map((d) => (d["week_name"]))
      const secondCall = data2.map((d)=>(d["count"]))
      // const Call = data3.map((d)=>(d["count"]))
      // const Call1 = data4.map((d)=>(d["count"]))
      // console.log("data type", data1)
      // console.log("data type", data2)
      // const sectordate = data12.map((d) => d["reg_day_name"])


      var options = {
          series: [{
          name: 'Collection',
          data: sectorCall
        }, {
          name: 'Transplant',
          data: secondCall
        },
        ],
          chart: {
          type: 'bar',
          height: 350
        },
        colors:['#1bbc9b','rgb(0, 143, 251)','#8A2BE2','#D2691E'],
        plotOptions: {
          bar: {
          columnWidth: '55%',
          borderRadius: 5,
          startingShape: 'rounded',
          endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          
        },
        xaxis: {
          categories: days,
        },
        yaxis: {
          // title: {
          //   text: '(thousands)'
          // }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return  + val + " "
            }
          }
        }
        };
        var chart = new ApexCharts(document.querySelector("#chart"), options);
        chart.render();
       
  
    }
  }
}
</script>
